<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">Managers</h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item">
                  <a routerLink="/">Teambuildr</a>
                </li>
                <li class="breadcrumb-item"><a href="#">A propos</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                  Managers
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<div class="container mt-100 mt-60">
  <div class="row align-items-center">
    <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
      <div class="section-title mr-lg-4">
        <h1 class="title mb-3">
          Logiciel pour les managers
        </h1>
        <p class="para-desc text-muted">
          Managers : Teambuildr vous aide à adapter votre management à la
          personnalité et à l'environnement de travail favorable de vos
          collaborateurs
        </p>

        <div class="row">
          <div class="col-12">
            <a
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#DemoForm"
              (click)="openModal(DemoForm)"
              class="btn btn-primary mt-4"
              >Demander une démo</a
            >
          </div>
          <!--end col-->
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-5 order-1 order-lg-2">
      <div class="saas-feature-shape-right position-relative">
        <img
          src="assets/images/tbr/assets/formation.png"
          class="img-fluid mx-auto d-block rounded"
          alt=""
        />
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>

<app-theytrustus></app-theytrustus>

<div class="row justify-content-center mt-4">
  <div class="col-12 text-center mt-4">
    <div class="section-title mb-4 pb-2">
      <h4 class="title mb-4">
        Teambuildr vous donne les clefs pour améliorer votre management !
      </h4>
      <p>
        Nous intégrons 60 ans de psychométrie dans notre solution pour vous
        permettre d'adapter <br/> votre communication et votre management à chaque
        collaborateur de votre équipe
      </p>
    </div>
    <!--end col-->
  </div>
</div>

<div class="container pb-5 mb-md-5 mt-100 mt-60">
  <div class="row align-items-center">
    <div class="col-lg-5">
      <div class="saas-feature-shape-left position-relative">
        <img
          src="assets/images/saas/classic22.png"
          class="img-fluid mx-auto d-block rounded shadow"
          alt=""
        />
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
      <div class="section-title ml-lg-4">
        <h1 class="title mb-3">
          Constructeur d'équipe
        </h1>
        <p class="para-desc text-muted">
          Grâce au constructeur d'équipes, vous pourrez créer une équipe
          complémentaire en quelques clics.
        </p>

        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center pt-4">
              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >Analyse des affinités entre collègues
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >Analyse du meilleur environnement de travail pour l'équipe
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >Modélisation psychométrique des relations entre les
                  collaborateurs
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 pt-4">
            <a href="/features/teambuilder" class="btn btn-outline-primary"
              >En savoir plus sur le constructeur d'équipe
              <i class="mdi mdi-chevron-right"></i
            ></a>
          </div>
          <!--end col-->
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>

<div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Cartographie des compétences
          </h1>
          <p class="para-desc text-muted">
            Gérez vos données métiers et compétences
          </p>
  
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Accès complet aux profils et compétences de toute
                    l'entreprise
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Cartographiez les Hard et les Soft Skills de vos
                    collaborateurs
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Différenciez le niveau de compétences et l'interêt pour
                    chaque matière (l'envie d'un senior peut avoir baissé sur une
                    matière et peut vouloir développer autre chose)
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->
  
      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/saas/classic13.png"
            class="img-fluid mx-auto d-block rounded "
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

<div class="container pb-5 mb-md-5 mt-100 mt-60">
  <div class="row align-items-center">
    <div class="col-lg-5">
      <div class="saas-feature-shape-left position-relative">
        <img
          src="assets/images/saas/classic09.png"
          class="img-fluid mx-auto d-block rounded shadow"
          alt=""
        />
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
      <div class="section-title ml-lg-4">
        <h1 class="title mb-3">
          Annuaire intelligent
        </h1>
        <p class="para-desc text-muted">
          L'annuaire Teambuildr vous permet de rechercher rapidement des profils
          et des compétences en interne
        </p>

        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center pt-4">
              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >Recherche par nom / poste / service
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >Recherche par Hard Skills
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >Recherche par Soft Skills
                </li>
              </ul>
            </div>
          </div>
          <!--end col-->
          <div class="col-12 pt-4">
            <a href="/features/directory" class="btn btn-outline-primary"
              >En savoir plus sur l'annuaire intelligent
              <i class="mdi mdi-chevron-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>



<div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Suivez le moral et l'éngagement de vos collaborateurs
          </h1>
          <p class="para-desc text-muted">
            Grâce au Baromètre du bien-être, vous pouvez demander chaque semaine "Comment ça va ?" et concentrer vos efforts sur les collaborateurs en souffrance
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Pop-up "Comment ça va ?" chaque semaine
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyses en temps réel
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    > Les salariés qui se sentent entendus et qui constatent que leurs feedbacks sont réellement pris en compte seront davantage engagés et motivés dans leur travail.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 pt-4">
                <a href="/features/wellbeing" class="btn btn-outline-primary"
                  >En savoir plus sur le baromètre du bien-être
                  <i class="mdi mdi-chevron-right"></i
                ></a>
              </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/saas/classic10.png"
            class="img-fluid mx-auto d-block rounded "
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

    <div class="container mt-100 mt-60">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="saas-feature-shape-left position-relative">
            <img
              src="assets/images/saas/classic07.png"
              class="img-fluid mx-auto d-block rounded shadow"
              alt=""
            />
          </div>
        </div>
        <!--end col-->

        <div class="col-lg-7 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="section-title ml-lg-4">
            <h4 class="title mb-3">
              Gestion des entretiens,
              <span class="text-primary">évaluations et analyses</span>
            </h4>
            <p class="text-muted">
              Gérez l'évaluation des performances de vos employés avec un outil
              à 360° connecté à l’application mobile. Grâce aux analyses des
              entretiens, vous pourrez suivre les performances, mettre en place
              des plans de formation et développer les compétences de vos
              collaborateurs.
            </p>
            <div class="mt-4">
              <a href="javascript:void(0)" class="btn btn-primary"
                >En savoir plus sur les entretiens
                <i class="mdi mdi-chevron-right"></i
              ></a>
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex align-items-center pt-4">
              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >Profitez de nos modèles validés par des DRH expérimentés.
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >Analyses en temps réel et recommandations
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >Tous les entretiens sont signés électroniquement et archivés
                  dans le Drive
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 pt-4">
            <a href="/features/interviews" class="btn btn-outline-primary"
              >En savoir plus sur les entretiens
              <i class="mdi mdi-chevron-right"></i
            ></a>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>

    <app-triptique></app-triptique>
    <app-call-to-action></app-call-to-action>

    <div class="mb-4" id="spacer"></div>

    <ng-template #DemoForm let-modal>
      <app-modal-demo [pageName]="'Managers'"></app-modal-demo>
    </ng-template>
