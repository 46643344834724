<section
  class="bg-home  align-items-center mb-4"
  style="height: auto;"
  id="pricing"
>
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Combien ça coute ?</h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            Avec
            <span class="text-primary font-weight-bold">Teambuildr</span> les
            prix sont transparents.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-end">
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow">
          <div class="price-header border-bottom pt-5 pb-5">
            <h1 class="text-primary"><i class="uil uil-lightbulb-alt"></i></h1>
            <h5 class="price-title">Sans engagement</h5>
            <p class="mb-0 text-muted">Pour découvrir la solution</p>
          </div>
          <div class="border-bottom py-4">
            <h2 class="font-weight-bold">8 €</h2>
            <h6 class="text-muted mb-0 font-weight-normal">
              par collaborateur / mois
            </h6>
            <a target="_blank" href="https://panel.teambuildr.fr/account/signup" class="btn btn-primary mt-4"
              >Inscrivez-vous gratuitement</a
            >
          </div>
          <div class="pricing-features text-left p-4">
            <h5>Incluant :</h5>
            <ul class="feature list-unstyled mb-0">
              <li class="text-muted">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Réseau social Entreprise (RSE)
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Application web & mobile
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Tableau de bord RSE
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Annuaire intelligent
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Sondages
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Portail employé
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Drive Coffre-fort numérique 1 To
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Onboarding
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Baromètre du bien-être
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Gestion des entretiens et analyses
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Organigramme dynamique d'entreprise
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Constructeur d'équipes
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Accompagnement 1h/semaine avec un consultant Teambuildr
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Dossiers personnalisés
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Prise en charge de plusieurs sites
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Toutes les futures fonctionnalités
              </li>
            </ul>
          </div>
        </div>
        <!--end price three-->
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow-lg">
          <div class="price-header border-bottom bg-primary pt-5 pb-5">
            <h1 class="text-white-50"><i class="uil uil-award"></i></h1>
            <h5 class="price-title text-white">Abonnement</h5>
            <p class="mb-0 text-light">
              Gérez des process avancés et personnalisés
            </p>
          </div>
          <div class="border-bottom py-5">
            <h2 class="font-weight-bold">6 €</h2>
            <h6 class="text-muted mb-0 font-weight-normal">
              par collaborateur / mois
            </h6>
            <a href="javascript:void(0)" data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)" class="btn btn-primary mt-4"
              >Demandez une démo</a
            >
          </div>
          <div class="pricing-features text-left p-4">
            <h5>Incluant :</h5>
            <ul class="feature list-unstyled mb-0">
              <li class="text-muted">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Réseau social Entreprise (RSE)
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Application web & mobile
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Tableau de bord RSE
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Annuaire intelligent
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Sondages
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Portail employé
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Drive Coffre-fort numérique 1 To
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Onboarding
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Baromètre du bien-être
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Gestion des entretiens et analyses
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Organigramme dynamique d'entreprise
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Constructeur d'équipes
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Accompagnement 1h/semaine avec un consultant Teambuildr
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Dossiers personnalisés
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Prise en charge de plusieurs sites
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Toutes les futures fonctionnalités
              </li>
            </ul>
          </div>
        </div>
        <!--end price three-->
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow">
          <div class="price-header border-bottom pt-5 pb-5">
            <h1 class="text-primary"><i class="uil uil-briefcase-alt"></i></h1>
            <h5 class="price-title">Premium</h5>
            <p class="mb-0 text-muted">
              Adapté aux besoins de votres entreprise
            </p>
          </div>
          <div class="border-bottom py-4">
            <h2 class="font-weight-bold">Tarif personnalisé</h2>
            <h6 class="text-muted mb-0 font-weight-normal">-</h6>
            <a href="javascript:void(0)" data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)" class="btn btn-primary mt-4"
              >Demandez une démo</a
            >
          </div>
          <div class="pricing-features text-left p-4">
            <h5>Incluant :</h5>
            <ul class="feature list-unstyled mb-0">
              <li class="text-muted">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Inclus toutes les fonctionnalités des autres plans
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Rapports personnalisés
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Intégration (Slack, Microsoft, Google Suite...)
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Personnalisation des workflows
              </li>
              <li class="text-muted mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                Tableaux de données personnalisés
              </li>
              <li class="text-muted opacity-none">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
              <li class="text-muted opacity-none mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
              <li class="text-muted opacity-none mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
              <li class="text-muted opacity-none mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
              <li class="text-muted opacity-none mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
              <li class="text-muted opacity-none mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
              <li class="text-muted opacity-none mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
              <li class="text-muted opacity-none mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
              <li class="text-muted opacity-none mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
              <li class="text-muted opacity-none mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
              <li class="text-muted opacity-none mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
              <li class="text-muted opacity-none mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
              <li class="text-muted opacity-none mt-2">
                <i-feather
                  name="arrow-right"
                  class="fea icon-sm text-dark mr-2"
                ></i-feather>
                .
              </li>
            </ul>
          </div>
        </div>
        <!--end price three-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="media">
          <i-feather
            name="help-circle"
            class="fea icon-ex-md text-primary mr-2 mt-1"
          ></i-feather>
          <div class="media-body">
            <h5 class="mt-0">
              Comment fonctionne l'essai gratuit de 30 jours ?
            </h5>
            <p class="answer text-muted mb-0">
              Pour commencer votre essai gratuit, inscrivez-vous simplement sur
              Teambuildr et confirmez votre compte. Une fois votre essai
              commencé, vous pouvez tester toutes les fonctionnalités, sans
              limite, pendant 30 jours.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="media">
          <i-feather
            name="help-circle"
            class="fea icon-ex-md text-primary mr-2 mt-1"
          ></i-feather>
          <div class="media-body">
            <h5 class="mt-0">
              Ai-je besoin d'une carte de crédit pour commencer mon essai
              gratuit ?
            </h5>
            <p class="answer text-muted mb-0">
              Non, vous pouvez commencer votre essai gratuit sans ajouter
              d'informations de paiement.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="media">
          <i-feather
            name="help-circle"
            class="fea icon-ex-md text-primary mr-2 mt-1"
          ></i-feather>
          <div class="media-body">
            <h5 class="mt-0">
              Que se passe-t-il lorsque mon essai se termine
              ?
            </h5>
            <p class="answer text-muted mb-4">
              Une fois votre essai gratuit terminé, vous devrez actualiser votre
              plan de paiement pour continuer à utiliser Teambuildr. Si vous avez
              besoin de plus de temps pour tester Teambuildr, contactez un
              commercial <a href="javascript:void(0)" data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)" class="btn btn-primary mt-4"
              >Prendre rendez-vous</a
            >
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="media">
          <i-feather
            name="help-circle"
            class="fea icon-ex-md text-primary mr-2 mt-1"
          ></i-feather>
          <div class="media-body">
            <h5 class="mt-0">Quelles sont mes options de paiement ?</h5>
            <p class="answer text-muted mb-4">
              Vous pouvez payer avec l'une des principales cartes de crédit :
              Visa, MasterCard, American Express et Discover. Vous pouvez
              également payer par facture de débit direct si vous contactez un
              commercial <a href="javascript:void(0)" data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)" class="btn btn-primary mt-4"
              >Prendre rendez-vous</a
            >
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12">
        <div class="media">
          <i-feather
            name="help-circle"
            class="fea icon-ex-md text-primary mr-2 mt-1"
          ></i-feather>
          <div class="media-body">
            <h5 class="mt-0">
              Que se passe-t-il si mon entreprise grandit et que j'ai besoin
              d'inviter plus d'employés ?
            </h5>
            <p class="answer text-muted mb-0">
              À chaque nouvel employé invité, votre plan sera mis à jour
              automatiquement. En outre, lors de chaque fin de contrat, votre
              plan sera également mis à jour pour tenir compte de ce changement.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="media">
          <i-feather
            name="help-circle"
            class="fea icon-ex-md text-primary mr-2 mt-1"
          ></i-feather>
          <div class="media-body">
            <h5 class="mt-0">Puis-je annuler mon abonnement à tout moment ?</h5>
            <p class="answer text-muted mb-0">
              Oui, vous pouvez annuler à tout moment. Une fois annulé, votre
              abonnement ne sera pas renouvelé automatiquement, mais vous
              pourrez toujours utiliser Teambuildr jusqu'à la date de
              renouvellement.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="media">
          <i-feather
            name="help-circle"
            class="fea icon-ex-md text-primary mr-2 mt-1"
          ></i-feather>
          <div class="media-body">
            <h5 class="mt-0">Comment actualiser mon compte ?</h5>
            <p class="answer text-muted mb-0">
              Si vous avez déjà un compte, connectez-vous simplement et accédez
              à la section du plan de paiement du menu. Sélectionnez le plan de
              paiement de votre choix (mensuel/annuel) et remplissez vos
              informations de facturation pour continuer.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="media">
          <i-feather
            name="help-circle"
            class="fea icon-ex-md text-primary mr-2 mt-1"
          ></i-feather>
          <div class="media-body">
            <h5 class="mt-0">Mes données seront-elles en sécurité ?</h5>
            <p class="answer text-muted mb-0">
              Chez Teambuildr, la sécurité des données est notre priorité ! Pour
              cette raison, nous prenons des précautions supplémentaires lors de
              la sauvegarde de vos données. De plus, l’accès est sécurisé pour
              les employés grâce à un accès unique.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12">
        <div class="media">
          <i-feather
            name="help-circle"
            class="fea icon-ex-md text-primary mr-2 mt-1"
          ></i-feather>
          <div class="media-body">
            <h5 class="mt-0">Est-ce que les prix incluent les taxes ?</h5>
            <p class="answer text-muted mb-0">
              Teambuildr appliquera des taxes conformément à la législation en
              vigueur.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="media">
          <i-feather
            name="help-circle"
            class="fea icon-ex-md text-primary mr-2 mt-1"
          ></i-feather>
          <div class="media-body">
            <h5 class="mt-0">Avez-vous d'autres questions ?</h5>
            <p class="answer text-muted mb-0">
              Si vous avez d'autres questions, nous serons heureux de vous
              aider. <a href="javascript:void(0)" data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)" class="btn btn-primary mt-4"
              >Prendre rendez-vous</a
            >
            </p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
  </div>
  <app-call-to-action></app-call-to-action>

  <!--end container-->
</section>


<ng-template #DemoForm let-modal>
 <app-modal-demo [pageName]="'Tarifs'"></app-modal-demo>
</ng-template>