<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title">Décideurs</h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item">
                    <a routerLink="/">Teambuildr</a>
                  </li>
                  <li class="breadcrumb-item"><a href="#">A propos</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Décideurs
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            DRH et CEO : Prenez de meilleures décisions
          </h1>
          <p class="para-desc text-muted">
            Grâce au jumeau numérique de votre entreprise, suivez et pilotez votre entreprise plus facilement !
          </p>
  
          <div class="row">
            <div class="col-12">
              <a
                href="javascript:void(0)"
                data-toggle="modal"
                data-target="#DemoForm"
                (click)="openModal(DemoForm)"
                class="btn btn-primary mt-4"
                >Demander une démo</a
              >
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->
  
      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/tbr/assets/stats.png"
            class="img-fluid mx-auto d-block rounded"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  
  <app-theytrustus></app-theytrustus>
  
  <div class="row justify-content-center mt-4">
    <div class="col-12 text-center mt-4">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4">
          Le jumeaux numérique est la représentation simplifiée de <br/> votre entreprise pour faciliter vos prises de décisions.
        </h4>
      </div>
      <!--end col-->
    </div>
  </div>
  
  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="saas-feature-shape-left position-relative">
          <img
            src="assets/images/saas/classic23.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
  
      <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ml-lg-4">
          <h1 class="title mb-3">
            Suivez les performances dans votre organisations
          </h1>
          <p class="para-desc text-muted">
            Nos analyses en temps réel permettent de suivre les performances dans chaque service et pour chaque type de poste
          </p>
  
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyses en temps réel et recommandations
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Suivez les performances de vos collaborateurs toute l'année
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Suivez les KPIs des meilleures DRH
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  
  <div class="container mt-100 mt-60">
      <div class="row align-items-center">
        <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
          <div class="section-title mr-lg-4">
            <h1 class="title mb-3">
              Cartographie des compétences
            </h1>
            <p class="para-desc text-muted">
              Gérez vos données métiers et compétences
            </p>
    
            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center pt-4">
                  <ul class="list-unstyled text-muted">
                    <li class="mb-0">
                      <span class="text-primary h5 mr-2"
                        ><i class="uim uim-check-circle"></i></span
                      >Accès complet aux profils et compétences de toute
                      l'entreprise
                    </li>
                    <li class="mb-0">
                      <span class="text-primary h5 mr-2"
                        ><i class="uim uim-check-circle"></i></span
                      >Cartographiez les Hard et les Soft Skills de vos
                      collaborateurs
                    </li>
                    <li class="mb-0">
                      <span class="text-primary h5 mr-2"
                        ><i class="uim uim-check-circle"></i></span
                      >Différenciez le niveau de compétences et l'interêt pour
                      chaque matière (l'envie d'un senior peut avoir baissé sur une
                      matière et peut vouloir développer autre chose)
                    </li>
                  </ul>
                </div>
              </div>
              <!--end col-->
            </div>
          </div>
        </div>
        <!--end col-->
    
        <div class="col-lg-5 order-1 order-lg-2">
          <div class="saas-feature-shape-right position-relative">
            <img
              src="assets/images/saas/classic13.png"
              class="img-fluid mx-auto d-block rounded "
              alt=""
            />
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  
  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="saas-feature-shape-left position-relative">
          <img
            src="assets/images/saas/classic10.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
  
      <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
            <h1 class="title mb-3">
              Suivez le moral et l'éngagement de vos collaborateurs
            </h1>
            <p class="para-desc text-muted">
              Grâce au Baromètre du bien-être, vous pouvez demander chaque semaine "Comment ça va ?" et concentrer vos efforts sur les collaborateurs en souffrance
            </p>
  
            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center pt-4">
                  <ul class="list-unstyled text-muted">
                    <li class="mb-0">
                      <span class="text-primary h5 mr-2"
                        ><i class="uim uim-check-circle"></i></span
                      >Pop-up "Comment ça va ?" chaque semaine
                    </li>
                    <li class="mb-0">
                      <span class="text-primary h5 mr-2"
                        ><i class="uim uim-check-circle"></i></span
                      >Analyses en temps réel
                    </li>
                    <li class="mb-0">
                      <span class="text-primary h5 mr-2"
                        ><i class="uim uim-check-circle"></i></span
                      > Les salariés qui se sentent entendus et qui constatent que leurs feedbacks sont réellement pris en compte seront davantage engagés et motivés dans leur travail.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-12 pt-4">
                  <a href="/features/wellbeing" class="btn btn-outline-primary"
                    >En savoir plus sur le baromètre du bien-être
                    <i class="mdi mdi-chevron-right"></i
                  ></a>
                </div>
              <!--end col-->
            </div>
          </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Suivez l’activité sur le réseau social interne
          </h1>
          <p class="para-desc text-muted">
            Nos analyses en temps réel permettent de suivre l’engagement de vos
            collaborateurs sur le réseau social.
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Suivez l'engagement sur vos posts
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Suivez l'engagement sur vos sondages
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Suivez les rencontres entre collègues et les activités dans
                    les clubs internes
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->

            <div class="col-12 pt-4">
              <a href="/features/rse" class="btn btn-outline-primary"
                >En savoir plus sur les KPIs du réseau social
                <i class="mdi mdi-chevron-right"></i
              ></a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/saas/classic02.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  
  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="saas-feature-shape-left position-relative">
          <img
            src="assets/images/saas/classic04.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ml-lg-4">
          <h1 class="title mb-3">
            Lancez des sondages et analysez en temps réel les résultats
          </h1>
          <p class="para-desc text-muted">
            Lancez des sondages directement dans l’application web et mobile et
            consultez les analyses !
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Possibilité de faire des sondages 100% anonymes
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyses en temps réel
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Les salariés qui se sentent entendus et qui constatent que
                    leurs feedbacks sont réellement pris en compte seront
                    davantage engagés et motivés dans leur travail
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->

            <div class="col-12 pt-4">
              <a href="javascript:void(0)" class="btn btn-outline-primary"
                >En savoir plus sur les sondages
                <i class="mdi mdi-chevron-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  
      <app-triptique></app-triptique>
      <app-call-to-action></app-call-to-action>
  
      <div class="mb-4" id="spacer"></div>
  
      <ng-template #DemoForm let-modal>
        <app-modal-demo [pageName]="'Decideur'"></app-modal-demo>
      </ng-template>
  