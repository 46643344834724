<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title"> Annuaire intelligent </h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/">Teambuildr</a></li>
                  <li class="breadcrumb-item"><a href="#">Fonctionnalités</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Annuaire intelligent</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Annuaire intelligent
          </h1>
          <p class="para-desc text-muted">
            L'annuaire Teambuildr vous permet de rechercher rapidement des profils et des compétences en interne.
          </p>

          <div class="row">
            <div class="col-12">
                <a href="javascript:void(0)" data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)" class="btn btn-primary mt-4"
              >Demander une démo</a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/saas/classic09.png"
            class="img-fluid mx-auto d-block rounded"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>




<app-theytrustus></app-theytrustus>


<div class="row justify-content-center mt-4">
    <div class="col-12 text-center mt-4">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4">
          Toutes les informations de vos collaborateurs au même endroit et plus encore...
        </h4>
    </div>
    <!--end col-->
  </div>
</div>



<div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Cartographie des compétences
          </h1>
          <p class="para-desc text-muted">
            Gérez vos données métiers et compétences
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Accès complet aux profils et compétences de toute l'entreprise
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Cartographiez les Hard et les Soft Skills de vos collaborateurs
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Différenciez le niveau de compétences et l'interêt pour chaque matière (l'envie d'un senior peut avoir baissé sur une matière et peut vouloir développer autre chose)
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/saas/classic13.png"
            class="img-fluid mx-auto d-block rounded "
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>





<div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="saas-feature-shape-left position-relative">
          <img
            src="assets/images/saas/classic14.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ml-lg-4">
          <h1 class="title mb-3">
            Cartographie des personnalités
          </h1>
          <p class="para-desc text-muted">
            Nos test de personnalités intégrés à l'appli mobile vous aide à adapter votre management pour chaque type de personnalité !
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Adaptez votre management aux personnalités de vos collaborateurs
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Rendez votre com' interne impactante pour le plus grand nombre !
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Chaque analyse de personnalité est évolutive et disponible à tout moment dans l'annuaire
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>


  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Tous les documents du dossier de vos employés organisés et centralisé
          </h1>
          <p class="para-desc text-muted">
            Collectez et distribuez facilement tous les documents du dossier du personnel
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Pour la collecte, plus besoin de relance, l'appli le fait pour vous !
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Informez vos employés des nouveaux documents disponibles (bulletins de paie, contrats...)
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Téléchargez facilement des documents en masse
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/saas/classic15.png"
            class="img-fluid mx-auto d-block rounded "
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>


  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Protégez tous vos documents
          </h1>
          <p class="para-desc text-muted">
            Chez Teambuildr, nous prenons votre sécurité très au sérieux.<br>Nous protégeons vos document à l'aide d'un système efficace et d'un accès unique pour chaque utilisateur.
          </p>

          <div class="row">
            <div class="col-12">
                <a href="javascript:void(0)" data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)" class="btn btn-primary mt-4"
              >Demander une démo</a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/tbr/assets/meeting1.png"
            class="img-fluid mx-auto d-block rounded"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>



<app-triptique></app-triptique>
<app-call-to-action></app-call-to-action>

<div class="mb-4" id="spacer"></div>



<ng-template #DemoForm let-modal>
    <app-modal-demo [pageName]="'Annuaire'"></app-modal-demo>
</ng-template>