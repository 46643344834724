<div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <p class="text-muted">Approuvé par plus de 50 entreprises</p>
      </div>
      <div class="col-lg-2 col-md-2 col-6 text-center">
        <img
          src="assets/images/client/2s2i.png"
          class="avatar avatar-ex-lg"
          alt=""
        />
      </div>
      <!--end col-->
  
      <div class="col-lg-2 col-md-2 col-6 text-center">
        <img
          src="assets/images/client/MEERIAD.png"
          class="avatar avatar-ex-lg"
          alt=""
        />
      </div>
      <!--end col-->
  
      <div class="col-lg-2 col-md-2 col-6 text-center pt-5 pt-sm-0">
        <img
          src="assets/images/client/vinci.png"
          class="avatar avatar-ex-lg"
          alt=""
        />
      </div>
      <!--end col-->
  
      <div class="col-lg-2 col-md-2 col-6 text-center pt-5 pt-sm-0">
        <img
          src="assets/images/client/sogetec.png"
          class="avatar avatar-ex-lg"
          alt=""
        />
      </div>
      <!--end col-->
  
      <div class="col-lg-2 col-md-2 col-6 text-center pt-5 pt-sm-0">
        <img
          src="assets/images/client/baylibre.png"
          class="avatar avatar-ex-lg"
          alt=""
        />
      </div>
      <!--end col-->
     
    </div>
    <!--end row-->
  </div>