<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> All Products </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">Landrick</a></li>
                <li class="breadcrumb-item"><a routerLink="/index-shop">Shop</a></li>
                <li class="breadcrumb-item active" aria-current="page">Products</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Start Products -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-12">
        <div class="card border-0 sidebar sticky-bar">
          <div class="card-body p-0">
            <!-- SEARCH -->
            <div class="widget">
              <div id="search2" class="widget-search mb-0">
                <form role="search" method="get" id="searchform" class="searchform">
                  <div>
                    <input type="text" class="border rounded" name="s" id="s" placeholder="Search Keywords...">
                    <input type="submit" id="searchsubmit" value="Search">
                  </div>
                </form>
              </div>
            </div>
            <!-- SEARCH -->

            <!-- CATAGORIES -->
            <div class="widget mt-4 pt-2">
              <h4 class="widget-title">Catagories</h4>
              <ul class="list-unstyled mt-4 mb-0 blog-catagories">
                <li><a href="jvascript:void(0)">Men</a></li>
                <li><a href="jvascript:void(0)">Women</a></li>
                <li><a href="jvascript:void(0)">Electronics</a></li>
                <li><a href="jvascript:void(0)">Jewellery</a></li>
                <li><a href="jvascript:void(0)">Shoes</a></li>
                <li><a href="jvascript:void(0)">Kid’s Wear</a></li>
                <li><a href="jvascript:void(0)">Sports</a></li>
                <li><a href="jvascript:void(0)">Toys</a></li>
                <li><a href="jvascript:void(0)">Gift Corners</a></li>
              </ul>
            </div>
            <!-- CATAGORIES -->

            <!-- color -->
            <div class="widget mt-4 pt-2">
              <h4 class="widget-title">Color</h4>
              <ul class="list-unstyled mt-4 mb-0">
                <li class="list-inline-item"><a href="javascript:void(0)" class="px-3 py-1 rounded-pill bg-primary"></a>
                </li>
                <li class="list-inline-item"><a href="javascript:void(0)" class="px-3 py-1 rounded-pill bg-danger"></a>
                </li>
                <li class="list-inline-item"><a href="javascript:void(0)" class="px-3 py-1 rounded-pill bg-success"></a>
                </li>
                <li class="list-inline-item"><a href="javascript:void(0)" class="px-3 py-1 rounded-pill bg-info"></a>
                </li>
                <li class="list-inline-item"><a href="javascript:void(0)"
                    class="px-3 py-1 rounded-pill bg-secondary"></a></li>
                <li class="list-inline-item"><a href="javascript:void(0)" class="px-3 py-1 rounded-pill bg-warning"></a>
                </li>
              </ul>
            </div>
            <!-- COlor -->

            <!-- Top Products -->
            <div class="widget mt-4 pt-2">
              <h4 class="widget-title">Top Products</h4>
              <ul class="list-unstyled mt-4 mb-0">
                <li class="media align-items-center">
                  <a href="javascript:void(0)">
                    <img src="assets/images/shop/product/s1.jpg" class="img-fluid avatar avatar-small rounded shadow"
                      style="height:auto;" alt="">
                  </a>
                  <div class="content ml-3">
                    <a href="javascript:void(0)" class="text-dark h6">T-Shirt</a>
                    <h6 class="text-muted small font-italic mb-0 mt-1">$18.00 <del class="text-danger ml-2">$22.00</del>
                    </h6>
                  </div>
                </li>
                <li class="media align-items-center mt-2">
                  <a href="javascript:void(0)">
                    <img src="assets/images/shop/product/s3.jpg" class="img-fluid avatar avatar-small rounded shadow"
                      style="height:auto;" alt="">
                  </a>
                  <div class="content ml-3">
                    <a href="javascript:void(0)" class="text-dark h6">Watch</a>
                    <h6 class="text-muted small font-italic mb-0 mt-1">$18.00 <del class="text-danger ml-2">$22.00</del>
                    </h6>
                  </div>
                </li>
                <li class="media align-items-center mt-2">
                  <a href="javascript:void(0)">
                    <img src="assets/images/shop/product/s6.jpg" class="img-fluid avatar avatar-small rounded shadow"
                      style="height:auto;" alt="">
                  </a>
                  <div class="content ml-3">
                    <a href="javascript:void(0)" class="text-dark h6">Coffee Cup</a>
                    <h6 class="text-muted small font-italic mb-0 mt-1">$18.00 <del class="text-danger ml-2">$22.00</del>
                    </h6>
                  </div>
                </li>
                <li class="media align-items-center mt-2">
                  <a href="javascript:void(0)">
                    <img src="assets/images/shop/product/s8.jpg" class="img-fluid avatar avatar-small rounded shadow"
                      style="height:auto;" alt="">
                  </a>
                  <div class="content ml-3">
                    <a href="javascript:void(0)" class="text-dark h6">Wooden Stools</a>
                    <h6 class="text-muted small font-italic mb-0 mt-1">$18.00 <del class="text-danger ml-2">$22.00</del>
                    </h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-9 col-md-8 col-12 mt-5 pt-2 mt-sm-0 pt-sm-0">
        <div class="row align-items-center">
          <div class="col-lg-9 col-md-7">
            <div class="section-title">
              <h5 class="mb-0">Showing 1–15 of 47 results</h5>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="form custom-form">
              <div class="form-group mb-0">
                <select class="form-control custom-select" id="Sortbylist-job">
                  <option>Sort by latest</option>
                  <option>Sort by popularity</option>
                  <option>Sort by rating</option>
                  <option>Sort by price: low to high</option>
                  <option>Sort by price: high to low</option>
                </select>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s1.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-1.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Branded T-Shirt</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$16.00 <del class="text-danger ml-2">$21.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s2.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-2.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Shopping Bag</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$21.00 <del class="text-danger ml-2">$25.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s3.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-3.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Elegent Watch</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$5.00 <span class="text-success ml-1">30%
                      off</span> </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s4.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-4.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Casual Shoes</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$18.00 <del class="text-danger ml-2">$22.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s5.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-5.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Earphones</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$3.00</h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s6.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-6.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Elegent Mug</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$4.50 <del class="text-danger ml-2">$6.50</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s7.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-7.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Sony Headphones</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$9.99 <span class="text-success ml-2">20%
                      off</span> </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s8.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-8.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Wooden Stools</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$22.00 <del class="text-danger ml-2">$25.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s9.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-9.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Coffee Cup / Mug</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$16.00 <del class="text-danger ml-2">$21.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s10.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-10.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Sunglasses</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$21.00 <del class="text-danger ml-2">$25.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s11.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-11.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Loafer Shoes</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$5.00 <span class="text-success ml-1">30%
                      off</span> </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s12.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-12.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">T-Shirts</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$18.00 <del class="text-danger ml-2">$22.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s13.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-13.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Wooden Chair</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$16.00 <del class="text-danger ml-2">$21.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s14.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-14.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Women Block Heels</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$21.00 <del class="text-danger ml-2">$25.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative overflow-hidden">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s15.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-15.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">T-Shirts</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$5.00 <span class="text-success ml-1">30%
                      off</span> </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <!-- PAGINATION START -->
          <div class="col-12 mt-4 pt-2">
            <ul class="pagination justify-content-center mb-0">
              <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous"><i
                    class="mdi mdi-arrow-left"></i> Prev</a></li>
              <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
              <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
              <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
              <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next <i
                    class="mdi mdi-arrow-right"></i></a></li>
            </ul>
          </div>
          <!--end col-->
          <!-- PAGINATION END -->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Products -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
