<div class="container mt-100 mt-60">
    <div class="row pt-md-5 justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Ils en parlent mieux que nous</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-12 mt-4">
        <!-- <div id="customer-testi" class="owl-carousel owl-theme"> -->
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img
                src="assets/images/client/01.jpg"
                class="avatar avatar-small mr-3 rounded shadow"
                alt=""
              />
              <div
                class="media-body content p-3 shadow rounded bg-white position-relative"
              >
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                </ul>
                <p class="text-muted mt-2">
                  "Teambuildr nous aide à lancer nos campagnes d'entretiens trimestriels en interne automatiquement. Les analyses sont très utiles pour voir les axes d’amélioration et l'annuaire est très pratique pour rechercher les meilleures compétences pour nos clients."
                </p>
                <h6 class="text-primary">
                  - Sébastien Jan, <small class="text-muted">DRH @ BayLibre</small>
                </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img
                src="assets/images/client/02.jpg"
                class="avatar avatar-small mr-3 rounded shadow"
                alt=""
              />
              <div
                class="media-body content p-3 shadow rounded bg-white position-relative"
              >
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star-half text-warning"></i>
                  </li>
                </ul>
                <p class="text-muted mt-2">
                  " Teambuildr me fait gagner du temps avec les sondages. J'implique mes collaborateurs et je prends de meilleures décisions. Avec 4 questions, le logiciel est capable d'orienter un nouveau collaborateur dans l'équipe ou il y aura le moins de conflits possibles."
                </p>
                <h6 class="text-primary">
                  - Jad Diab, <small class="text-muted">CEO @ SOGETEC INGENIERIE</small>
                </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img
                src="assets/images/client/03.jpg"
                class="avatar avatar-small mr-3 rounded shadow"
                alt=""
              />
              <div
                class="media-body content p-3 shadow rounded bg-white position-relative"
              >
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                </ul>
                <p class="text-muted mt-2">
                  " Des entretiens trimestriels automatisés avec des indicateurs clairs et des analyses de personnalités pour savoir comment parler à mes collaborateurs c’est innovant ! "
                </p>
                <h6 class="text-primary">
                  - Raphael Deckers, <small class="text-muted">CEO @ Entropeak</small>
                </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img
                src="assets/images/client/04.jpg"
                class="avatar avatar-small mr-3 rounded shadow"
                alt=""
              />
              <div
                class="media-body content p-3 shadow rounded bg-white position-relative"
              >
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                </ul>
                <p class="text-muted mt-2">
                  " Facile d’utilisation et très efficient. "
                </p>
                <h6 class="text-primary">
                  - François Rivet, <small class="text-muted">CEO @ Hexatio</small>
                </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img
                src="assets/images/client/08.jpg"
                class="avatar avatar-small mr-3 rounded shadow"
                alt=""
              />
              <div
                class="media-body content p-3 shadow rounded bg-white position-relative"
              >
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                </ul>
                <p class="text-muted mt-2">
                  " SPLEEEEENDIIIIIDE "
                </p>
                <h6 class="text-primary">
                  - Théo Pascard, <small class="text-muted">CEO @ Meeriad</small>
                </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img
                src="assets/images/client/07.jpg"
                class="avatar avatar-small mr-3 rounded shadow"
                alt=""
              />
              <div
                class="media-body content p-3 shadow rounded bg-white position-relative"
              >
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                </ul>
                <p class="text-muted mt-2">
                  " Mes relations avec mon manager sont vraiment devenues simples, je fais mes entretiens directement depuis mon téléphone et j’ai accès aux comptes rendus dans mon drive Teambuildr. "
                </p>
                <h6 class="text-primary">
                  - Alexi Colombain, <small class="text-muted">Chargé de recrutement @ 2S2I Groupe</small>
                </h6>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>