<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" href="/" *ngIf="navClass !== 'nav-light'">
        <img src="assets/images/logo-dark.png" class="l-dark" height="24" alt="">
        <img src="assets/images/logo-dark.png" class="l-light" height="24" alt="">
      </a>
      <a class="logo" href="/" *ngIf="navClass === 'nav-light'">
        <img src="assets/images/logo-dark.png" class="l-dark" height="24" alt="">
        <img src="assets/images/logo-light.png" class="l-light" height="24" alt="">
      </a>
    </div>
    <!--end login button-->
    <!-- End Logo container-->
    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a href="/" class="nav-link-ref">Accueil</a></li>
        <li><a href="/pricing" class="nav-link-ref">Tarifs</a></li>
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Fonctionnalités</a><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li><a class="nav-link-ref" href="/features/directory">Annuaire intelligent</a></li>
                <li><a class="nav-link-ref" href="/features/rse">Réseau social</a></li>
                <li><a class="nav-link-ref" href="/features/polls">Sondages</a></li>
                <li><a class="nav-link-ref" href="/features/onboarding">Onboarding</a></li>
                <li><a class="nav-link-ref" href="/features/wellbeing">Baromètre du bien-être</a></li>
                <li><a class="nav-link-ref" href="/features/interviews">Entretiens</a></li>
                <li><a class="nav-link-ref" href="/features/teambuilder">Constructeur d'équipe</a></li>
                <li><a class="nav-link-ref" href="/about/seedmanagement">Seed management</a></li>
                <!-- <li><a class="nav-link-ref" href="/features/dynamictree">Organigramme dynamique</a></li> -->
                <li><a class="nav-link-ref" href="/about/mobileapp">Application mobile</a></li>
              </ul>
            </li>
          </ul>
        </li>

        <!-- <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Pages</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Company </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/page-aboutus"> About Us</a></li>
                <li><a class="nav-link-ref" href="/page-aboutus-two"> About Us Two </a>
                </li>
                <li><a class="nav-link-ref" href="/page-services">Services</a></li>
                <li><a class="nav-link-ref" href="/page-history">History </a></li>
                <li><a class="nav-link-ref" href="/page-team"> Team</a></li>
                <li><a class="nav-link-ref" href="/page-pricing">Pricing</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Account </a><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/account-profile">Profile</a></li>
                <li><a class="nav-link-ref" href="/account-members">Members </a>
                </li>
                <li><a class="nav-link-ref" href="/account-works">Works </a></li>
                <li><a class="nav-link-ref" href="/account-messages">Messages </a>
                </li>
                <li><a class="nav-link-ref" href="/account-payments">Payments </a>
                </li>
                <li><a class="nav-link-ref" href="/account-setting">Setting</a></li>
                <li><a class="nav-link-ref" href="/page-invoice">Invoice</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Shop </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/shop-products">All Products</a></li>
                <li><a class="nav-link-ref" href="/shop-product-detail">Product Details</a></li>
                <li><a class="nav-link-ref" href="/shop-cart">Shop Cart</a></li>
                <li><a class="nav-link-ref" href="/shop-checkouts">Checkouts</a></li>
                <li><a class="nav-link-ref" href="/shop-myaccount">My Account</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Help center </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/helpcenter-overview">Helpcenter</a></li>
                <li><a class="nav-link-ref" href="/helpcenter-faqs">Faqs</a></li>
                <li><a class="nav-link-ref" href="/helpcenter-guides">Guides</a></li>
                <li><a class="nav-link-ref" href="/helpcenter-support-request">Support Call</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Forums </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/forums">Overview </a></li>
                <li><a class="nav-link-ref" href="/forums-topic">Forum Topic </a>
                </li>
                <li><a class="nav-link-ref" href="/forums-comments">Forum Comments </a>
                </li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Email Template</a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/email-confirmation">Confirmation</a></li>
                <li><a class="nav-link-ref" href="/email-password-reset">Reset Password</a></li>
                <li><a class="nav-link-ref" href="/email-alert">Alert</a></li>
                <li><a class="nav-link-ref" href="/email-invoice">Invoice</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)">Careers</a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/page-jobs">Jobs</a></li>
                <li><a class="nav-link-ref" href="/page-jobs-sidebar">Jobs - Sidebar</a></li>
                <li><a class="nav-link-ref" href="/page-job-detail">Job Detail</a></li>
                <li><a class="nav-link-ref" href="/page-job-apply">Job Apply</a></li>
                <li><a class="nav-link-ref" href="/page-job-company">Company</a></li>
                <li><a class="nav-link-ref" href="/page-job-candidate">Candidate</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Blog </a><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/page-blog-grid">Blog Grid</a></li>
                <li><a class="nav-link-ref" href="/page-blog-sidebar">Blog with Sidebar</a></li>
                <li><a class="nav-link-ref" href="/page-blog-list">Blog Listing</a></li>
                <li><a class="nav-link-ref" href="/page-blog-list-sidebar">Blog List & Sidebar</a></li>
                <li><a class="nav-link-ref" href="/page-blog-detail">Blog Detail</a></li>
                <li><a class="nav-link-ref" href="/page-blog-detail-two">Blog Detail 2 </a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Case Study </a><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/page-cases">All Cases </a></li>
                <li><a class="nav-link-ref" href="/page-case-detail">Case Detail </a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Works</a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/page-work-modern">Works Modern</a></li>
                <li><a class="nav-link-ref" href="/page-work-classic">Works Classic</a></li>
                <li><a class="nav-link-ref" href="/page-work-grid">Works Grid</a></li>
                <li><a class="nav-link-ref" href="/page-work-masonry">Works Masonry</a></li>
                <li><a class="nav-link-ref" href="/page-work-detail">Work Detail</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Auth Pages</a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/auth-login">Login</a></li>
                <li><a class="nav-link-ref" href="/auth-cover-login">Login Cover</a></li>
                <li><a class="nav-link-ref" href="/auth-login-three">Login Simple</a></li>
                <li><a class="nav-link-ref" href="/auth-signup">Signup</a></li>
                <li><a class="nav-link-ref" href="/auth-cover-signup">Signup Cover</a></li>
                <li><a class="nav-link-ref" href="/auth-signup-three">Signup Simple</a></li>
                <li><a class="nav-link-ref" href="/auth-re-password">Reset Password</a></li>
                <li><a class="nav-link-ref" href="/auth-cover-re-password">Reset Password Cover</a></li>
                <li><a class="nav-link-ref" href="/auth-re-password-three">Reset Password Simple</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Utility </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/page-terms">Terms of Services</a></li>
                <li><a class="nav-link-ref" href="/page-privacy">Privacy Policy</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Special </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/page-comingsoon">Coming Soon</a></li>
                <li><a class="nav-link-ref" href="/page-comingsoon2">Coming Soon Two</a></li>
                <li><a class="nav-link-ref" href="/page-maintenance">Maintenance</a></li>
                <li><a class="nav-link-ref" href="/page-error">Error</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Contact </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" href="/page-contact-detail">Contact Detail</a></li>
                <li><a class="nav-link-ref" href="/page-contact-one">Contact One</a></li>
                <li><a class="nav-link-ref" href="/page-contact-two">Contact Two</a></li>
                <li><a class="nav-link-ref" href="/page-contact-three">Contact Three</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Docs</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a class="nav-link-ref" href="/documentation">Documentation</a></li>
            <li><a class="nav-link-ref" href="/changelog">Changelog</a></li>
            <li><a class="nav-link-ref" href="/components">Components</a></li>
            <li><a class="nav-link-ref" href="/widget">Widget</a></li>
          </ul>
        </li> -->
        <li><a href="https://blog.teambuildr.fr/home/blog" target="_blank" class="nav-link-ref">Blog</a></li>
        <li><a href="/contact" class="nav-link-ref">Contact</a></li>
        <li class="has-submenu keep-hover-color">
          <a href="javascript:void(0)" class="btn btn-pills btn-primary btn-login-navbar" (click)="onMenuClick($event)">Connexion</a>
          <ul class="submenu">
            <li><a class="nav-link-ref" href="https://app.teambuildr.fr">Employé</a></li>
            <li><a class="nav-link-ref" href="https://panel.teambuildr.fr">Administrateur</a></li>
          </ul>
        </li>

      </ul>
      <!--end navigation menu-->
      <!--end login button-->
      <!--end login button-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->