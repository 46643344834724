<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/" class="btn btn-icon btn-soft-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<!-- COMING SOON PAGE -->
<section class="bg-home bg-animation-left dark-left d-flex align-items-center" data-jarallax='{"speed": 0.5}'
  style="background-image: url('assets/images/comingsoon2.jpg');" id="home">
  <a id="video" class="player"></a>

  <video autoplay muted loop id="myVideo">
    <source src="http://techslides.com/demos/sample-videos/small.mp4" />
  </video>

  <div class="container position-relative text-md-left text-center" style="z-index: 1;">
    <div class="row">
      <div class="col-md-12">
        <a href="javascript:void(0)" class="logo h5"><img src="assets/images/logo-light.png" height="24" alt=""></a>
        <h1 class="text-uppercase text-white title-dark mt-2 mb-4 coming-soon">
          <ngx-typed-js [strings]="['We are Coming soon...', 'UI/UX Designer', 'Web Developer']" [shuffle]="true"
            [typeSpeed]="80" [loop]="true">
            <span class="element typing"></span>
          </ngx-typed-js>
        </h1>
        <p class="text-light para-dark para-desc">Start working with <span class="font-weight-bold">Landrick</span> that
          can provide everything you need to generate awareness, drive traffic, connect.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div id="countdown">
          <div class="count-down"><span class="count-number">{{_days}}</span><span
              class="count-head position-relative d-block">Days</span>
          </div>
          <div class="count-down"><span class="count-number">{{_hours}}</span><span
              class="count-head position-relative d-block">Hours</span>
          </div>
          <div class="count-down"><span class="count-number">{{_minutes}}</span><span
              class="count-head position-relative d-block">Minutes</span>
          </div>
          <div class="count-down"><span class="count-number">{{_seconds}}</span><span
              class="count-head position-relative d-block">Seconds</span>
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a routerLink="/" data-toggle="modal" data-target="#LoginForm" class="btn btn-primary mt-4 mr-2"><i
            class="mdi mdi-check"></i> Notify Me</a>
        <a routerLink="/" class="btn btn-outline-primary mt-4"><i class="mdi mdi-backup-restore"></i> Go Back
          Home</a>
      </div>
    </div>
  </div> <!-- end container -->
</section>
<!-- COMING SOON PAGE -->

<!-- Subscribe start -->
<div class="modal fade" id="LoginForm" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content rounded shadow border-0">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">Subscribe Me</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="bg-white p-3 rounded box-shadow">
          <p>We are comingsoon. Please, Enter your mail id and subscribe.</p>
          <form>
            <div class="form-group mb-0">
              <div class="input-group">
                <input name="email" id="email" type="email" class="form-control" placeholder="Your Email :" required=""
                  aria-describedby="newssubscribebtn">
                <div class="input-group-append">
                  <button class="btn btn-primary submitBnt" type="submit" id="paypalmail">Subscribe</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Subscribe end -->