<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/" class="btn btn-icon btn-soft-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<!-- Hero Start -->
<section class="bg-home bg-circle-gradiant d-flex align-items-center">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-8">
        <div class="card login-page bg-white shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">Login</h4>
            <form class="login-form mt-4">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <label>Your Email <span class="text-danger">*</span></label>
                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                    <input type="email" class="form-control pl-5" placeholder="Email" name="email" required="">
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <label>Password <span class="text-danger">*</span></label>
                    <i-feather name="key" class="fea icon-sm icons"></i-feather>
                    <input type="password" class="form-control pl-5" placeholder="Password" required="">
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="d-flex justify-content-between">
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                        <label class="custom-control-label" for="customCheck1">Remember me</label>
                      </div>
                    </div>
                    <p class="forgot-pass mb-0"><a routerLink="/auth-re-password-three"
                        class="text-dark font-weight-bold">Forgot password ?</a></p>
                  </div>
                </div>
                <div class="col-lg-12 mb-0">
                  <button class="btn btn-primary btn-block">Sign in</button>
                </div>
                <div class="col-lg-12 mt-4 text-center">
                  <h6>Or Login With</h6>
                  <ul class="list-unstyled social-icon mb-0 mt-3">
                    <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="facebook" class="fea icon-sm icons"></i-feather>
                      </a></li>
                    <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="github" class="fea icon-sm icons"></i-feather>
                      </a></li>
                    <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="twitter" class="fea icon-sm icons"></i-feather>
                      </a></li>
                    <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="gitlab" class="fea icon-sm icons"></i-feather>
                      </a></li>
                  </ul>
                  <!--end icon-->
                </div>
                <div class="col-12 text-center">
                  <p class="mb-0 mt-3"><small class="text-dark mr-2">Don't have an account ?</small> <a
                      routerLink="/auth-signup-three" class="text-dark font-weight-bold">Sign Up</a></p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!---->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
