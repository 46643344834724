import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  email: string = null;
  emailRegex:RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(private modalService: NgbModal, private http: HttpClient) { }


  /**
    * Open modal for show the video
    * @param content content of modal
    */
   openVideoModal(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  ngOnInit(): void {
  }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  subscribeReq() {
    return this.http.post<any>("https://api-beta.teambuildr.fr/api/v1/landing/newsletter/getStarted", {email : this.email});
  }

  submitTrialRequest(modal){
    if(this.email !== null && this.email.match(this.emailRegex) !== null ){
      this.subscribeReq().subscribe((res) => {
        if(res.success == true){
          this.modalService.open(modal, { centered: true });
          this.email = null;
        }
      })
    }else{
      alert('Le mail renseigné est invalide.')
    }
  }

}
