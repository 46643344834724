import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-theytrustus',
  templateUrl: './theytrustus.component.html',
  styleUrls: ['./theytrustus.component.css']
})
export class TheytrustusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
