<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title">Contactez-nous</h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/">Teambuildr</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Contactez-nous</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->
  
  <!-- Start Contact -->
  <section class="section pb-0">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="card contact-detail text-center border-0">
            <div class="card-body p-0">
              <div class="icon">
                <img src="assets/images/icon/bitcoin.svg" class="avatar avatar-small" alt="">
              </div>
              <div class="content mt-3">
                <h4 class="title font-weight-bold">Téléphone</h4>
                <a href="tel:+152534-468-854" class="text-primary">+33 6 35 78 08 34</a>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
  
        <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="card contact-detail text-center border-0">
            <div class="card-body p-0">
              <div class="icon">
                <img src="assets/images/icon/Email.svg" class="avatar avatar-small" alt="">
              </div>
              <div class="content mt-3">
                <h4 class="title font-weight-bold">Email</h4>
                <a href="mailto:contact@example.com" class="text-primary">contact@teambuildr.fr</a>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
  
        <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="card contact-detail text-center border-0">
            <div class="card-body p-0">
              <div class="icon">
                <img src="assets/images/icon/location.svg" class="avatar avatar-small" alt="">
              </div>
              <div class="content mt-3">
                <h4 class="title font-weight-bold">Localisation</h4>
                <p class="text-muted">4 Rue Saint-Sidoine, 69006, Lyon</p>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <div class="container mt-100 mt-60">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
          <div class="card shadow rounded border-0">
            <div class="card-body py-5">
              <h4 class="card-title">Contact</h4>
              <div class="custom-form mt-4">
                <div id="message"></div>
                <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group position-relative">
                        <label>Nom <span class="text-danger">*</span></label>
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <input [(ngModel)]="message.name" name="message[name]" id="name" type="text" class="form-control pl-5" placeholder="Jean Dupont">
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-6">
                      <div class="form-group position-relative">
                        <label>Email <span class="text-danger">*</span></label>
                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                        <input [(ngModel)]="message.email" name="message[email]" id="email" type="email" class="form-control pl-5" placeholder="exemple@gmail.com">
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-12">
                      <div class="form-group position-relative">
                        <label>Objet</label>
                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                        <input [(ngModel)]="message.topic" name="message[topic]" id="subject" type="text" class="form-control pl-5" placeholder="Objet">
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-12">
                      <div class="form-group position-relative">
                        <label>Message</label>
                        <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                        <textarea [(ngModel)]="message.message" name="message[message]" id="comments" rows="4" class="form-control pl-5"
                          placeholder="Votre message..."></textarea>
                      </div>
                    </div>
                  </div>
                  <!--end row-->
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <input (click)="submitMessage(ContactForm)" type="submit" id="submit" name="send" class="submitBnt btn btn-primary btn-block"
                        value="Envoyer">
                      <div id="simple-msg"></div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
                <!--end form-->
              </div>
              <!--end custom-form-->
            </div>
          </div>
        </div>
        <!--end col-->
  
        <div class="col-lg-7 col-md-6 order-1 order-md-2">
          <div class="card border-0">
            <div class="card-body p-0">
              <img src="assets/images/contact.svg" class="img-fluid" alt="">
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
   
  </section>
  <!--end section-->
  <!-- End contact -->
  

  <ng-template #ContactForm let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Message énvoyé</h5>
    </div>
    <div class="modal-body">
        <div class="bg-white p-3 rounded box-shadow">
        <p>Votre message nous est bien parvenu ! <br/> Nous vous répondrons au plus vite.</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Fermer</button>
      </div>
  </ng-template>
  