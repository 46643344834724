<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Changelog </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">Landrick</a></li>
                <li class="breadcrumb-item"><a href="#">Docs</a></li>
                <li class="breadcrumb-item active" aria-current="page">Changelog</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Changelog Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="p-4 shadow rounded border">
          <!-- v2.5.1 -->
          <h5 class="mt-5">Version <span class="text-success">2.5.1</span> - 16th December 2020 <span
              class="badge badge-pill badge-danger ml-2">Latest</span></h5>
          <ul class="list-unstyled">
            <li class="text-muted my-2 ml-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm mr-2"></i-feather>
              <span class="font-weight-bold">Fixed :</span> Initial Release
            </li>
          </ul>
          <!-- v2.5.1 -->

          <div class="mt-4">
            <a href="#" target="_blank" class="btn btn-primary">Purchase Now</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Changelog End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
