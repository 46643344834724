import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private http: HttpClient, private modalService: NgbModal) { }

  message: any = {
    name: null,
    email : null,
    topic: null,
    message: null,
  }

  ngOnInit(): void {
  }

  sendMessage(content) {
    return this.http.post<any>("https://api-beta.teambuildr.fr/api/v1/landing/demo/request", {content : content});
  }

  async submitMessage(modal){
    if(this.message.name == null || this.message.email == null || this.message.topic == null || this.message.message == null){
      return;
    }
      let content = `Nouveau message depuis la page contact : \n
      Nom : ${this.message.name} \n 
      Email : ${this.message.email} \n 
      Sujet : ${this.message.topic} \n 
      Message : ${this.message.message} \n 
      `;
      this.sendMessage(content).subscribe((res) => {
        if(res.success){
          this.modalService.open(modal, { centered: true });
          this.message = {
            name: null,
            email : null,
            topic: null,
            message: null,
          }
        }
      })
  }

}
