<div class="container mt-100 mt-60">
    <div class="rounded bg-primary p-lg-5 p-4">
      <div class="row align-items-end">
        <div class="col-md-12">
          <div class="section-title text-md-left text-center">
            <h4 class="title mb-3 text-white title-dark">
              Prêt à déployer une nouvelle façon de manager dans votre entreprise ?
            </h4>
            <p class="text-white-50 mb-0 text-center">
              Découvre la solution dès maintenant
            </p>
          </div>
        </div>
        <!--end col-->

        <div class="text-center subcribe-form mt-4 pt-2">
            <form class="mb-4">
              <div class="form-group mb-0">
                <input
                  type="email"
                  id="email"
                  name="email"
                  class="border bg-white rounded-pill"
                  required
                  placeholder="Email"
                  [(ngModel)]="email"
                />
                <button type="submit" class="btn btn-pills btn-primary" (click)="submitTrialRequest(TrialForm)" >
                  Essayez gratuitement
                </button>
              </div>
            </form>
            <a
            href="javascript:void(0)"
            class="text-white mt-4 font-weight-bold"
            data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)"
          >
            Demander une démo
            <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
          </a>
          </div>
      </div>
      <!--end row-->
    </div>
  </div>
  <!--end container-->

  <ng-template #DemoForm let-modal>
    <app-modal-demo [pageName]="'Acceuil'"></app-modal-demo>
   </ng-template>

   <ng-template #TrialForm let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Confirmation</h5>
    </div>
    <div class="modal-body">
      <div class="bg-white p-3 rounded box-shadow">
        <p>Merci ! <br/> Un mail vous a été envoyé pour commencer à tester la solution.</p>
      </div>
    </div>
   </ng-template>