<section class="bg-half" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10">
          <div class="section-title">
            <div class="text-center">
              <h4 class="title mb-4">Qu'est-ce que le Seed Management ?</h4>
              <img src="assets/images/blog/05.jpg" class="img-fluid rounded-md shadow-md" alt="">
            </div>
            <p class=" mb-0 mt-4">Plantez vos graines dans le bon environnement</p>
 
            <h4 class="my-4">Chaque graine a besoin d'un environnement adapté pour pousser.</h4>
            <p class="">Nous intégrons des analyses de personnalités validées par la communauté scientifique pour vous permettre d'adapter votre management à vos collaborateurs.</p>
 
            <h4 class="my-4">On rejoint une société, on quitte un manager</h4>
            <p class="">Il n'y a pas qu'une seule façon de manager, il faut s'adapter aux personnalités de son équipe !</p>
            <p class=" mb-0">Teambuildr vous permet de savoir quels types de graines vous avez dans votre entreprise et dans quel environnement les placer pour qu'elles donnent les plus beaux fruits.</p>
 
            <h4 class="my-4">Lexique</h4>
                <ul>
                    <li>Graine = collaborateur</li>
                    <li>Terreau = Poste, missions, formations, outils</li>
                    <li>Climat = Vision de l'entreprise, ambiance de travail, marque employeur, équipe...</li>
                </ul>

            <h4 class="my-4">Le jardinier, c'est Teambuildr</h4>
            <p class="">Chez Teambuildr, nous éditons une solution logicielle couplée à un réseau social d’entreprise pour faciliter le lien entre les collaborateurs et les managers.</p>
            <p class=" mb-3">Nous créons un jumeau numérique de votre organisation et nous vous donnons les clefs managériales pour gérer au mieux les ressources humaines, même à distance, basé sur 60 ans d’études psychométriques.</p>
            <p class=" mb-3">Dans notre langage interne, chaque collaborateur est une graine et notre solution introduit le concept du Seed management, c’est-à-dire qu’à partir d’analyses de personnalités validées par la communauté scientifique couplées à des bilans de compétences personnalisables et automatisables, nous indiquons dans quel environnement favorable mettre chaque graine qui composent les entreprises.</p>
            <p class=" mb-3">Nous possédons également une fonctionnalité qui n’existe nulle part ailleurs, le ✨constructeur d’équipes✨(Teambuilder en anglais)</p>
            <p class=" mb-3">L’ambition de Teambuildr est de créer les meilleures équipes au monde ! Teambuildr entend également participer à la prévention des RPS (risques psycho sociaux) dans le entreprises grâce à des sondages anonymisés crées par des partenaires du projet tels que le cabinet Cognitiva Consulting et envoyés via le réseau social aux employés pour récupérer des informations utiles qui sont analysés directement depuis l’outil.</p>
            <p class=" mb-3">La solution a également pour vocation de remonter les besoins de formations afin  d'aider les entreprises à favoriser l’employabilité de leurs salarié(e)s.</p>
 
                <div class="col-12">
                    <a
                      href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#DemoForm"
                      (click)="openModal(DemoForm)"
                      class="btn btn-primary mt-4"
                      >Demander une démo</a
                    >
                  </div>
            <!--end row-->
 
 

          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
 
  <ng-template #DemoForm let-modal>
    <app-modal-demo [pageName]="'MobileApp'"></app-modal-demo>
  </ng-template>
