<!-- Hero Start -->
<section
  class="bg-home d-flex align-items-center bg-light"
  id="home"
  style="height: auto;"
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9 text-center mt-0 mt-md-5 pt-0 pt-md-5">
        <div class="title-heading margin-top-100">
          <h1 class="heading mb-4">
            Fédérez vos équipes à distance avec
            <span class="text-primary">Teambuildr</span>
          </h1>
          <p class="para-desc mx-auto text-muted">
            Le réseau social d’entreprise connecté à un logiciel de
            communication et de management RH innovant.
          </p>
          <div class="text-center subcribe-form mt-4 pt-2">
            <form>
              <div class="form-group mb-0">
                <input
                  type="email"
                  id="email"
                  name="email"
                  class="border bg-white rounded-pill"
                  required
                  placeholder="Email"
                  [(ngModel)]="email"

                />
                <button type="submit" class="btn btn-pills btn-primary" (click)="submitTrialRequest(TrialForm)">
                  Essayer gratuitement
                </button>
              </div>
            </form>
          </div>
          <ul class="mt-4 list-unstyled mb-0 align-items-center">
            <li class="list-inline-item">
              <a
                href="javascript:void(0)"
                class="text-primary font-weight-bold"
                data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)"
              >
                Demander une démo
                <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
              </a>
            </li>
          </ul>
        </div>

        <div class="home-dashboard cursor-pointer">
          <img
            src="assets/images/social/hero.png"
            alt=""
            class="img-fluid mover"
            (click)="openVideoModal(VideoModal)"
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<app-theytrustus></app-theytrustus>
<!--end container-->

<!-- Feature Start -->
<section class="section">
  <app-triptique></app-triptique>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 col-12">
        <img
          src="assets/images/social/download.png"
          class="img-fluid mx-auto d-block"
          alt=""
        />
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <div class="alert alert-light alert-pills" role="alert">
            <span class="badge badge-primary badge-pill mr-1">Gratuit</span>
            <span class="content"
              >Application employé
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </span>
          </div>
          <h4 class="title mb-4">
            Disponible gratuitement sur <br />
            Android et iOS
          </h4>
          <p class="text-muted para-desc mb-0">
            <span class="text-primary font-weight-bold"
              >L'application mobile</span
            >
            est disponible gratuitement pour vos collaborateurs sur Android et
            iOS. <br /><br />
            Newsfeed interne, Clubs d’activités entre collègues, sondages,
            rencontres aléatoires entre employés, messagerie instantanée, Drive,
            Dossier RH, recherche de compétences, et bien plus encore !
          </p>
          <div class="my-4">
            <a target="_blank" href="https://apps.apple.com/us/app/teambuildr/id1532755292" class="btn btn-lg btn-dark mt-2 mr-2"
              ><i class="mdi mdi-apple"></i> App Store</a
            >
            <a target="_blank" href="https://play.google.com/store/apps/details?id=io.teambuildr.tbr" class="btn btn-lg btn-dark mt-2"
              ><i class="mdi mdi-google-play"></i> Play Store</a
            >
          </div>

          <div class="d-inline-block">
            <div class="pt-4 d-flex align-items-center border-top">
              <i-feather
                name="smartphone"
                class="fea icon-md mr-2 text-primary"
              ></i-feather>
              <div class="content">
                <a href="/about/mobileapp" class="text-primary"
                  >Découvrez toutes les fonctionnalités de l'appli pour les
                  employés <i class="mdi mdi-arrow-right"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end mobile download-->


  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Suivez l’activité sur le réseau social interne
          </h1>
          <p class="para-desc text-muted">
            Nos analyses en temps réel permettent de suivre l’engagement de vos
            collaborateurs sur le réseau social.
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Suivez l'engagement sur vos posts
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Suivez l'engagement sur vos sondages
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Suivez les rencontres entre collègues et les activités dans
                    les clubs internes
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->

            <div class="col-12 pt-4">
              <a href="/features/rse" class="btn btn-outline-primary"
                >En savoir plus sur les KPIs du réseau social
                <i class="mdi mdi-chevron-right"></i
              ></a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/saas/classic02.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end rse dashboard -->

  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="saas-feature-shape-left position-relative">
          <img
            src="assets/images/saas/classic04.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ml-lg-4">
          <h1 class="title mb-3">
            Lancez des sondages et analysez en temps réel les résultats
          </h1>
          <p class="para-desc text-muted">
            Lancez des sondages directement dans l’application web et mobile et
            consultez les analyses !
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Possibilité de faire des sondages 100% anonymes
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyses en temps réel
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Les salariés qui se sentent entendus et qui constatent que
                    leurs feedbacks sont réellement pris en compte seront
                    davantage engagés et motivés dans leur travail
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->

            <div class="col-12 pt-4">
              <a href="/features/polls" class="btn btn-outline-primary"
                >En savoir plus sur les sondages
                <i class="mdi mdi-chevron-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end poll-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 col-12">
        <div class="social-feature-left">
          <img src="assets/images/social/1.png" class="img-fluid" alt="" />
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ml-lg-4">
          <h4 class="title mb-3">
            Gestion éléctroniques des documents <br />
            <span class="text-primary">Drive de 1To</span>
          </h4>
          <p class="text-muted">
            Stockez l'ensemble de vos documents sur des serveurs sécurisés à
            forte disponibilité.
          </p>
        </div>
        <div class="col-12">
          <div class="d-flex align-items-center">
            <ul class="list-unstyled text-muted">
              <li class="mb-0">
                <span class="text-primary h5 mr-2"
                  ><i class="uim uim-check-circle"></i></span
                >Téléchargez, partagez et organisez en toute sécurité les
                documents de votre entreprise.
              </li>
              <li class="mb-0">
                <span class="text-primary h5 mr-2"
                  ><i class="uim uim-check-circle"></i></span
                >Définissez les dossiers personnalisés pour chaques
                collaborateurs
              </li>
              <li class="mb-0">
                <span class="text-primary h5 mr-2"
                  ><i class="uim uim-check-circle"></i></span
                >Stockez des contrats et autres documents pour une récupération
                rapide des données.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end drive-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">Onboarding des nouveaux collaborateurs</h1>
          <p class="para-desc text-muted">
            Assurez-vous d'être au top pour acceuillir de nouveaux talents dans
            vos équipes ! Leur intégration à l'entreprise sera simple, rapide et
            surtout satisfaisante.
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Personnalisez et automatisez les workflows d’onboarding des
                    nouveaux
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Guidez les nouveaux collaborateurs au travers d'étapes
                    adaptées.
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Collectez des informations importantes et des feedbacks
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->

            <div class="col-12 pt-4">
              <a href="/features/onboarding" class="btn btn-outline-primary"
                >En savoir plus sur l'intégration des nouveaux collaborateurs
                <i class="mdi mdi-chevron-right"></i
              ></a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/saas/classic05.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end onboarding-->

  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="saas-feature-shape-left position-relative">
          <img
            src="assets/images/saas/classic06.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ml-lg-4">
          <h1 class="title mb-3">
            Suivez le moral et l’engagement de vos collaborateurs
          </h1>
          <p class="para-desc text-muted">
            Grâce au Baromètre du bien-être, vous pouvez demander chaque semaine
            « comment ca va » et concentrer vos efforts sur les collaborateurs
            en souffrance
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Pop-up « comment ca va ? » chaque semaine
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyses en temps réel
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Les salariés qui se sentent entendus et qui constatent que
                    leurs feedbacks sont réellement pris en compte seront
                    davantage engagés et motivés dans leur travail
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->

            <div class="col-12 pt-4">
              <a href="/features/wellbeing" class="btn btn-outline-primary"
                >En savoir plus sur le baromètre du bien-être
                <i class="mdi mdi-chevron-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end wellbeing-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="saas-feature-shape-left position-relative">
          <img
            src="assets/images/saas/classic07.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ml-lg-4">
          <h4 class="title mb-3">
            Gestion des entretiens,
            <span class="text-primary">évaluations et analyses</span>
          </h4>
          <p class="text-muted">
            Gérez l'évaluation des performances de vos employés avec un outil à
            360° connecté à l’application mobile. Grâce aux analyses des
            entretiens, vous pourrez suivre les performances, mettre en place
            des plans de formation et développer les compétences de vos
            collaborateurs.
          </p>
          <div class="mt-4">
            <a href="/features/interviews" class="btn btn-primary"
              >En savoir plus sur les entretiens
              <i class="mdi mdi-chevron-right"></i
            ></a>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex align-items-center pt-4">
            <ul class="list-unstyled text-muted">
              <li class="mb-0">
                <span class="text-primary h5 mr-2"
                  ><i class="uim uim-check-circle"></i></span
                >Profitez de nos modèles validés par des DRH expérimentés.
              </li>
              <li class="mb-0">
                <span class="text-primary h5 mr-2"
                  ><i class="uim uim-check-circle"></i></span
                >Analyses en temps réel et recommandations
              </li>
              <li class="mb-0">
                <span class="text-primary h5 mr-2"
                  ><i class="uim uim-check-circle"></i></span
                >Tous les entretiens sont signés électroniquement et archivés
                dans le Drive
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end entretiens-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">Constructeur d’équipe</h1>
          <p class="para-desc text-muted">
            Grâce au constructeur d’équipe, vous pourrez créer des équipes
            complémentaires en quelques clics.
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyse des affinités entre collègues
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyse du meilleur meilleurs environnement de travail pour
                    l’équipe
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Modélisation psychométrique des relations entre les
                    collaborateurs
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->

            <div class="col-12 pt-4">
              <a href="/features/teambuilder" class="btn btn-outline-primary"
                >En savoir plus sur le constructeur d'équipe
                <i class="mdi mdi-chevron-right"></i
              ></a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/saas/classic08.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end teambuilder-->

  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="saas-feature-shape-left position-relative">
          <img
            src="assets/images/saas/classic09.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ml-lg-4">
          <h1 class="title mb-3">Annuaire intelligent</h1>
          <p class="para-desc text-muted">
            L’annuaire Teambuildr vous permet de rechercher rapidement des
            profils et des compétences en interne
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Recherche par nom / poste / service
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Rechercher par Hard Skills
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Rechercher par Soft Skills
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
            <div class="col-12 pt-4">
              <a href="/features/directory" class="btn btn-outline-primary"
                >En savoir plus sur l'annuaire intelligent
                <i class="mdi mdi-chevron-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end annuaire-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 col-12 order-1 order-md-2">
        <img src="assets/images/saas/stats.png" class="img-fluid" alt="" />
      </div>
      <!--end col-->

      <div
        class="col-lg-7 col-md-6 col-12 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0"
      >
        <div class="section-title mr-lg-4">
          <p class="text-primary h2 mb-3">
            <i class="uil uil-layer-group"></i>
          </p>
          <h4 class="title mb-3">
            Ce qui nous rend unique ?<br />
            <span class="text-primary">Le seed management !</span>
          </h4>
          <p class="text-muted">
            Nous intégrons 60 ans de psychométrie dans notre solution pour vous
            permettre d’adapter votre communication et votre management à chaque
            collaborateur
          </p>
        </div>

        <div class="col-12 pt-4">
          <a href="/about/seedmanagement" class="btn btn-outline-primary"
            >En savoir plus sur le seed management
            <i class="mdi mdi-chevron-right"></i
          ></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end seed management-->


  <app-setupworkflow></app-setupworkflow>
  <app-testimonies></app-testimonies>
  <app-socialnetworks></app-socialnetworks>
  <app-call-to-action></app-call-to-action>

</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!-- Social Icons , features and testi End -->


<ng-template #DemoForm let-modal>
 <app-modal-demo [pageName]="'Acceuil'"></app-modal-demo>
</ng-template>

<ng-template #DemoForm let-modal>
  <app-modal-demo [pageName]="'Acceuil'"></app-modal-demo>
 </ng-template>

 <ng-template #TrialForm let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Confirmation</h5>
  </div>
  <div class="modal-body">
    <div class="bg-white p-3 rounded box-shadow">
      <p>Merci ! <br/> Un mail vous a été envoyé pour commencer à tester la solution.</p>
    </div>
  </div>
 </ng-template>


 <ng-template #VideoModal let-modal>
  <div class="modal-body">
    <div class="bg-white p-3 rounded box-shadow">
      <iframe class="iframe-video" height="90vh" src="https://www.youtube.com/embed/FcUX1DgXX2w?autoplay=1" autoplay="1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;" allowfullscreen></iframe>
    </div>
  </div>
 </ng-template>