<div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">
            Tous vos outils de com' interne et de management au même endroit.
          </h4>
          <p class="text-muted para-desc mx-auto mb-0">
            Découvrez comment
            <span class="text-primary font-weight-bold">Teambuildr</span> peut
            aider votre entreprise !
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-4 col-12">
        <div class="features mt-5">
          <div class="image position-relative d-inline-block">
            <img
              src="assets/images/icon/stats.png"
              class="avatar avatar-medium"
              alt=""
            />
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Logiciel pour les managers</h4>
            <p class="text-muted mb-0">
              Adaptez votre management à la personnalité de vos collaborateurs grace à nos tests intégrés.<br/>
              Concentrez vous sur ce qui compte : vos équipes
            </p>
            <a href="/about/managers" class="text-primary"
            >Découvrez Teambuildr pour les managers
            <i class="mdi mdi-chevron-right"></i
          ></a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img
              src="assets/images/icon/poll.png"
              class="avatar avatar-medium"
              alt=""
            />
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Logiciel pour DRH et CEO</h4>
            <p class="text-muted mb-0">
              Améliorer l’ambiance de travail à distance et économisez de
              l’argent en prenant de meilleures décisions grâce aux KPIs Teambuildr.
            </p>
            <a href="/about/admins" class="text-primary"
            >Découvrez Teambuildr pour les décideurs
            <i class="mdi mdi-chevron-right"></i
          ></a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img
              src="assets/images/icon/rse.png"
              class="avatar avatar-medium"
              alt=""
            />
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Accès collaborateur</h4>
            <p class="text-muted mb-0">
              Découvrez toutes les fonctionnalités que nous proposons sur notre
              application web et mobile pour tous les employés.
            </p>
            <a href="/about/mobileapp" class="text-primary"
            >Découvrez Teambuildr pour les collaborateurs
            <i class="mdi mdi-chevron-right"></i
          ></a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>