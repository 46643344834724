<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Company Story </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">Landrick</a></li>
                <li class="breadcrumb-item"><a href="#">Company</a></li>
                <li class="breadcrumb-item active" aria-current="page">History</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="main-icon rounded-pill text-primary text-center mt-4 pt-2">
          <i-feather name="tool" class="fea icon-md-sm"></i-feather>
        </div>
        <div class="timeline-page pt-2 position-relative">
          <div class="timeline-item mt-4">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
              </div>
              <!--end col-->
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="card event event-description-right rounded shadow border-0 overflow-hidden float-left">
      
                  <div class="card-body">
                    <h5 class="mb-0">1. Mise en route instantanée</h5>
                    <p class="mt-3 mb-0 text-muted">Découvrez notre solution 100% cloud que vous pouvez
                      installer rapidement pour communiquer et remonter les
                      besoins de vos équipes à distance</p>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end timeline item-->
      
          <div class="timeline-item mt-4">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                <div class="card event event-description-left rounded shadow border-0 overflow-hidden float-left">
       
                  <div class="card-body">
                    <h5 class="mb-0">2. Indiquez les services, postes et compétences clefs</h5>
                    <p class="mt-3 mb-0 text-muted">Si vous le souhaitez, un consultant peut vous assister sur
                      toute ces étapes GRATUITEMENT</p>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">

              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end timeline item-->
      
          <div class="timeline-item mt-4">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
              </div>
              <!--end col-->
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="card event event-description-right rounded shadow border-0 overflow-hidden float-left">
      
                  <div class="card-body">
                    <h5 class="mb-0">3. Invitez les collaborateurs</h5>
                    <p class="mt-3 mb-0 text-muted">Importez une liste de mails ou inviter 1 par 1 les
                      collaborateurs sur le réseau social</p>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end timeline item-->
      
          <div class="timeline-item mt-4">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                <div class="card event event-description-left rounded shadow border-0 overflow-hidden float-left">
      
                  <div class="card-body">
                    <h5 class="mb-0">4. Vous êtes prêt à améliorez votre com’ interne et votre productivité</h5>
                    <p class="mt-3 mb-0 text-muted">Utilisez la solution et chaque action sera bénéfique pour
                      votre entreprise</p>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">

              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end timeline item-->
        </div>
        <!--end timeline page-->
        <!-- TIMELINE END -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</section>


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
