<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title"> KPI du réseau social</h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/">Teambuildr</a></li>
                  <li class="breadcrumb-item"><a href="#">Fonctionnalités</a></li>
                  <li class="breadcrumb-item active" aria-current="page">KPI du réseau social</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Suivez l'engagement et l'activité sur le réseau social
          </h1>
          <p class="para-desc text-muted">
            Découvrez comment vos collaborateurs utilisent Teambuildr
          </p>

          <div class="row">
            <div class="col-12">
                <a href="javascript:void(0)" data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)" class="btn btn-primary mt-4"
              >Demander une démo</a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/tbr/assets/escalade.png"
            class="img-fluid mx-auto d-block rounded"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>




<app-theytrustus></app-theytrustus>


<div class="row justify-content-center mt-4">
    <div class="col-12 text-center mt-4">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4">
          Votre meilleur outil de communication interne
        </h4>
    </div>
    <!--end col-->
  </div>
</div>



<div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Créer les news les plus impactantes
          </h1>
          <p class="para-desc text-muted">
            Nos analyses en temps réel permettent de suivre l'engagement pour les posts
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyse en temps réel des likes et des commentaires
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >10 façons d'améliorer sa com' interne
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Consultez tous les rapports d'analyses à n'importe quel moment
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/tbr/assets/tmbldr.png"
            class="img-fluid mx-auto d-block rounded "
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>





<div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="saas-feature-shape-left position-relative">
          <img
            src="assets/images/tbr/assets/sondages.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ml-lg-4">
          <h1 class="title mb-3">
            Améliorez le taux de réponse à vos sondages
          </h1>
          <p class="para-desc text-muted">
            Nos analyses en temps réel permettent de suivre les réponses à vos sondages et vous aident à définir les leviers pour améliorer vos enquêtes internes
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyse en temps réel du taux de réponses aux sondages
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >5 façons d'améliorer le taux de réponse aux sondages
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Consultez tous les sondages dans un tableau de bord ergonomique
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Suivez et sponsorisez les clubs les plus populaires !
          </h1>
          <p class="para-desc text-muted">
            Les clubs internes permettent aux employés de créer de manière autonome des clubs selon leurs centres d'interêts
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Suivez l'engagement pour les clubs internes !
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Sponsorisez les clubs les plus populaires réduit votre budget évènementiel !
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Les clubs favorisent le teambuilding autonome !
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/tbr/assets/meeting2.png"
            class="img-fluid mx-auto d-block rounded "
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>


<app-triptique></app-triptique>
<app-call-to-action></app-call-to-action>

<div class="mb-4" id="spacer"></div>



<ng-template #DemoForm let-modal>
    <app-modal-demo [pageName]="'RSE KPI'"></app-modal-demo>
</ng-template>