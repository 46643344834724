    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Demande de démo</h5>
    </div>
    <div class="modal-body">
      <div class="bg-white p-3 rounded box-shadow">
          <p *ngIf="isSent">
              Merci ! <br/> Un consultant Teambuildr va vous contacter rapidement.
          </p>
        <form class="login-form" *ngIf="!isSent">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group position-relative">
                  <label>Prénom <span class="text-danger">*</span></label>
                  <i-feather name="user" class="fea icon-sm icons"></i-feather>
                  <input [(ngModel)]="demo.Firstname" name="demo[Firstname]" type="text" class="form-control pl-5" placeholder="Prénom" name="s" required="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group position-relative">
                  <label>Nom <span class="text-danger">*</span></label>
                  <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                  <input [(ngModel)]="demo.Lastname" name="demo[Lastname]" type="text" class="form-control pl-5" placeholder="Nom" name="s" required="">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group position-relative">
                  <label>Adresse email <span class="text-danger">*</span></label>
                  <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                  <input [(ngModel)]="demo.Email" name="demo[Email]" type="email" class="form-control pl-5" placeholder="Email" name="email" required="">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group position-relative">
                  <label>Téléphone <span class="text-danger">*</span></label>
                  <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                  <input [(ngModel)]="demo.Phone" name="demo[Phone]" type="text" class="form-control pl-5" placeholder="Téléphone" required="">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input [(ngModel)]="demo.Accepted" name="demo[Accepted]" type="checkbox" class="custom-control-input" id="customCheck1">
                    <label class="custom-control-label" for="customCheck1">J'accepte d'être contacté(e) par un consultant Teambuildr</label>
                  </div>
                </div>
              </div>
            </div>
          </form>
      </div>
    </div>
    <div class="modal-footer">
      <button *ngIf="!isSent && demo.Accepted" type="button" class="btn btn-outline-dark" (click)="submitDemoRequest()">Envoyer la demande</button>
    </div>