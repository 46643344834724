<p>teambuilder works!</p>
<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title"> Constructeur d'équipe</h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/">Teambuildr</a></li>
                  <li class="breadcrumb-item"><a href="#">Fonctionnalités</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Constructeur d'équipe</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Le constructeur d'équipe, Teambuildr
          </h1>
          <p class="para-desc text-muted">
            Et si l'on pouvait cliquer sur un bouton pour se rappeler de toutes les caractéristiques des collaborateurs pour créer les meilleures équipes au monde ?
          </p>

          <div class="row">
            <div class="col-12">
                <a href="javascript:void(0)" data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)" class="btn btn-primary mt-4"
              >Demander une démo</a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/tbr/assets/meeting1.png"
            class="img-fluid mx-auto d-block rounded"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>




<app-theytrustus></app-theytrustus>


<div class="row justify-content-center mt-4">
    <div class="col-12 text-center mt-4">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4">
          Créez les meilleures équipes au monde !
        </h4>
    </div>
    <!--end col-->
  </div>
</div>



<div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Créez la meilleure équipe selon vos besoins
          </h1>
          <p class="para-desc text-muted">
            En fonction de votre besoin créez une équipe qui possède les meilleures affinités et les mêmes façons de travailler !
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Astuce N°1 : Pour une besoin court terme, privilégiez les personnalités qui gèrent plutôt bien le stress
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Astuce N°2 : Pour un besoin sur une mission plus long terme, préférez des juniors + seniors qui aiment travailler de la même façon
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Astuce N°100 : Affectez une personne performante en bien-être avec une autre moins performante avec un score de compatibilité supérieur à 70%
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/saas/classic21.png"
            class="img-fluid mx-auto d-block rounded "
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>





<div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="saas-feature-shape-left position-relative">
          <img
            src="assets/images/saas/classic22.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ml-lg-4">
          <h1 class="title mb-3">
            Constructeur d'équipe
          </h1>
          <p class="para-desc text-muted">
            Grâce au constructeur d'équipes, vous pourrez créer une équipe complémentaire en quelques clics.
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyse des affinités entre collègues
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyse du meilleur environnement de travail pour l'équipe
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Modélisation psychométrique des relations entre les collaborateurs
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>


<app-triptique></app-triptique>
<app-call-to-action></app-call-to-action>

<div class="mb-4" id="spacer"></div>



<ng-template #DemoForm let-modal>
    <app-modal-demo [pageName]="'Teambuilder'"></app-modal-demo>
</ng-template>