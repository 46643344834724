import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setupworkflow',
  templateUrl: './setupworkflow.component.html',
  styleUrls: ['./setupworkflow.component.css']
})
export class SetupworkflowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
