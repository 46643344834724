<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title"> Onboarding </h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/">Teambuildr</a></li>
                  <li class="breadcrumb-item"><a href="#">Fonctionnalités</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Onboarding</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Guidez vos collaborateurs et remontez des infos clefs !
          </h1>
          <p class="para-desc text-muted">
            Teambuildr vous permet de personnaliser et d'automatiser des workflow d'onboarding et de remonter des KPI pour améliorer vos processus internes d'intégration des nouveaux
          </p>

          <div class="row">
            <div class="col-12">
                <a href="javascript:void(0)" data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)" class="btn btn-primary mt-4"
              >Demander une démo</a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/tbr/assets/escalade.png"
            class="img-fluid mx-auto d-block rounded"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>




<app-theytrustus></app-theytrustus>


<div class="row justify-content-center mt-4">
    <div class="col-12 text-center mt-4">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4">
          Un onboarding réussi est l'étape clef N°1 dans la rétention des talents !
        </h4>
    </div>
    <!--end col-->
  </div>
</div>



<div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Personnalisez chaque étapes de la vie d'une(e) employé(e)
          </h1>
          <p class="para-desc text-muted">
            Le workflow Teambuildr permet de mettre en place très simplement des messages et des sondages automatisés pour piloter l'onboarding de vos nouveaux employés
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Messages personnalisables
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyses en temps réel
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Et le réseau social qui complète l'intégration de manière autonome !
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/saas/classic05.png"
            class="img-fluid mx-auto d-block rounded "
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>





<div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="saas-feature-shape-left position-relative">
          <img
            src="assets/images/saas/classic04.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ml-lg-4">
          <h1 class="title mb-3">
            Message de pré-rentrée et d'accueil
          </h1>
          <p class="para-desc text-muted">
            Paramétrez automatiquement des messages avant que le collaborateur vous rejoigne dans l'entreprise pour le rassurer et lui donner de l'info !
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Rassurer
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Informer
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Impliquer avant le jour J
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>


  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Rapport d'étonnement
          </h1>
          <p class="para-desc text-muted">
            Paramétrez automatiquement un rapport d'étonnement X jours après l'arrivée dans votre entreprise et remontez des indicateurs clefs pour améliorer vos processus internes !
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >KPI's Teambuildr
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Personnalisez vos rapports d'étonnement
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Suivez les analyses en temps réel
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/saas/classic12.png"
            class="img-fluid mx-auto d-block rounded "
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>



<app-triptique></app-triptique>
<app-call-to-action></app-call-to-action>

<div class="mb-4" id="spacer"></div>



<ng-template #DemoForm let-modal>
    <app-modal-demo [pageName]="'Onboarding'"></app-modal-demo>
</ng-template>