<div class="row justify-content-center">
    <div class="col-lg-6 col-md-8 pb-md-4">
      <ul class="text-center mb-0 p-0">
        <li class="list-inline-item mx-2 mt-3">
          <a
            href="https://www.facebook.com/jointeambuildr"
            class="btn btn-icon btn-pills btn-lg btn-blue"
            placement="top"
            ngbTooltip="Facebook"
            target="_blank"
          >
            <i-feather name="facebook" class="icons"></i-feather>
          </a>
        </li>
        <li class="list-inline-item mx-2 mt-3">
          <a
            href="https://www.instagram.com/jointeambuildr/"
            class="btn btn-icon btn-pills btn-lg btn-danger"
            placement="top"
            ngbTooltip="Instagram"
            target="_blank"
          >
            <i-feather name="instagram" class="icons"></i-feather>
          </a>
        </li>
        <li class="list-inline-item mx-2 mt-3">
          <a
            href="https://www.linkedin.com/company/34973361"
            class="btn btn-icon btn-pills btn-lg btn-secondary"
            placement="top"
            ngbTooltip="Linkedin"
            target="_blank"
          >
            <i-feather name="linkedin" class="icons"></i-feather>
          </a>
        </li>
        <li class="list-inline-item mx-2 mt-3">
          <a
            href="https://twitter.com/jointeambuildr"
            class="btn btn-icon btn-pills btn-lg btn-info"
            placement="top"
            ngbTooltip="Twitter"
            target="_blank"
          >
            <i-feather name="twitter" class="icons"></i-feather>
          </a>
        </li>
        <li class="list-inline-item mx-2 mt-3">
          <a
            href="https://www.youtube.com/channel/UCL67_79AOKd0LkSn_Gmae5w"
            class="btn btn-icon btn-pills btn-lg btn-danger"
            placement="top"
            ngbTooltip="Youtube"
            target="_blank"
          >
            <i-feather name="youtube" class="icons"></i-feather>
          </a>
        </li>
      </ul>
    </div>
    <!--end col-->
  </div>
  <!--end row-->