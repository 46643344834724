import { Component, Input, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-modal-demo',
  templateUrl: './modal-demo.component.html',
  styleUrls: ['./modal-demo.component.css']
})
export class ModalDemoComponent implements OnInit {

  demo:any = {
    Email: null,
    Phone: null,
    Firstname: null,
    Lastname: null,
    Accepted: false
  }
  isSent:boolean = false;

  @Input() pageName: string; 

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  sendDemo(content) {
    return this.http.post<any>("https://api-beta.teambuildr.fr/api/v1/landing/demo/request", {content : content});
  }

  async submitDemoRequest(){
      let content = `Nouvelle demande de demo depuis la page [${this.pageName}] : \n Mail : ${this.demo.Email} \n Telephone :  ${this.demo.Phone} \n Nom : ${this.demo.Firstname} ${this.demo.Lastname}`;
      this.sendDemo(content).subscribe((res) => {
        this.isSent = true;
      })
  }

}
