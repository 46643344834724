<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title"> Sondages </h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/">Teambuildr</a></li>
                  <li class="breadcrumb-item"><a href="#">Fonctionnalités</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Sondages</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Favorisez l'intelligence collective !
          </h1>
          <p class="para-desc text-muted">
            Les salariés qui se sentent entendus et qui constatent que leurs feedbacks sont réellement pris en compte seront davantage engagés et motivés dans leur travail.
          </p>

          <div class="row">
            <div class="col-12">
                <a href="javascript:void(0)" data-toggle="modal" data-target="#DemoForm" (click)="openModal(DemoForm)" class="btn btn-primary mt-4"
              >Demander une démo</a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/tbr/assets/stats.png"
            class="img-fluid mx-auto d-block rounded"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>




<app-theytrustus></app-theytrustus>


<div class="row justify-content-center mt-4">
    <div class="col-12 text-center mt-4">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4">
          Les sondages sont essentiels pour garder le lien avec vos collaborateurs.
        </h4>
    </div>
    <!--end col-->
  </div>
</div>



<div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title mr-lg-4">
          <h1 class="title mb-3">
            Améliorez le taux de réponse à vos sondages
          </h1>
          <p class="para-desc text-muted">
            Nos analyses en temps réel permettent de suivre les réponses à vos sondages et vous aident à définir les leviers pour améliorer vos enquêtes internes
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyse en temps réel du taux de réponses
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >5 façons d'améliorer le taux de réponse
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Consultez tous les sondages dans un tableau de bord ergonomique
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/tbr/assets/sondages.png"
            class="img-fluid mx-auto d-block rounded "
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>





<div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="saas-feature-shape-left position-relative">
          <img
            src="assets/images/saas/classic04.png"
            class="img-fluid mx-auto d-block rounded shadow"
            alt=""
          />
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ml-lg-4">
          <h1 class="title mb-3">
            Lancez des sondages et analysez en temps réel les résultats
          </h1>
          <p class="para-desc text-muted">
            Lancez des sondages directement dans l’application web et mobile et
            consultez les analyses !
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Possibilité de faire des sondages 100% anonymes
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analyses en temps réel
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Les salariés qui se sentent entendus et qui constatent que
                    leurs feedbacks sont réellement pris en compte seront
                    davantage engagés et motivés dans leur travail
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>



<app-triptique></app-triptique>
<app-call-to-action></app-call-to-action>

<div class="mb-4" id="spacer"></div>



<ng-template #DemoForm let-modal>
    <app-modal-demo [pageName]="'Sondages'"></app-modal-demo>
</ng-template>